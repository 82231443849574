<template>
  <section>
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form
          :inline="true"
          ref="selectForm1"
          :model="selectForm1"
          class="select_form"
        >
          <el-form-item label="年:">
            <el-input v-model="selectForm1.Year" placeholder="年"></el-input>
          </el-form-item>
          <el-form-item label="月:">
            <el-input v-model="selectForm1.Month" placeholder="月"></el-input>
          </el-form-item>
          <toolbar
            :buttonList="buttonList"
            @callFunction="callFunction"
            :buttonListmsg="buttonListmsg"
          ></toolbar>
        </el-form>
      </el-col>
    </el-row>

    <div class="new_css calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
      
        @row-dblclick="handleSEdit"
      
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="CreateUserName" label="创建人">
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="CreateDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column label="年" prop="Year"></el-table-column>
        <el-table-column label="月" prop="Month"></el-table-column>
        <el-table-column label="状态" prop="State">
          <template slot-scope="scope">
            <el-tag
              :type="scope.row.State == '未锁定' ? 'success' : 'danger'"
              disable-transitions
            >
              {{ scope.row.State }}
            </el-tag>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      title="编辑"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="120px"
      >
        <el-form-item
          label="姓名:"
          prop="UserName"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-input type="text" v-model="SakaryForm.UserName" :disabled="true">
          </el-input>
        </el-form-item>

        <el-form-item
          label="身份证号"
          prop="IdCard"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-input type="text" v-model="SakaryForm.IdCard" :disabled="true">
          </el-input>
        </el-form-item>

        <el-form-item
          label="个税金额"
          prop="Salary"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-input
            type="text"
            v-model="SakaryForm.Salary"
            @blur="inputMoney('Salary')"
          ></el-input>
        </el-form-item>
        <el-row>
          <el-col :span="24" style="text-align: center">
          
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>

    <el-dialog
      title="个税记录"
      :visible.sync="InsuranceVisible"
      v-model="InsuranceVisible"
      :close-on-click-modal="false"
      width="80%"
    >
      <el-row>
        <el-col
          v-if="buttonList.length > 0"
          :span="24"
          class="organizetoolbar"
          style="padding-bottom: 0px"
        >
          <el-form :inline="true" ref="selectForm" :model="selectForm">
            <el-form-item label="名称:">
              <el-input
                v-model="selectForm.userName"
                placeholder="名称"
              ></el-input>
            </el-form-item>
            <!-- 这个就是当前页面内，所有的btn列表 -->
            <el-form-item>
              <!-- 这里触发点击事件 -->

              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="getInsuranceData()"
                >查询</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                :disabled="isDisabled"
                @click="handleInEdit()"
                >修改</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-delete"
                :disabled="isDisabled"
                @click="handleInDel()"
                >删除</el-button
              >
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-download"
                @click="doDown()"
                >导入模板下载</el-button
              >
            </el-form-item>
          </el-form>
          <el-upload
            action="https://api.gradgroup.cn/ftp/ftp/upload"
                     :headers ="token"
            :on-change="handleChange"
            :file-list="fileListUpload"
            :show-file-list="false"
            :limit="1"
            accept=".xlsx,.xls"
            :auto-upload="false"
          >
            <el-button
              :loading="disbtn"
              size="small"
              :disabled="isDisabled"
              type="primary"
              >上传 Excel</el-button
            >
          </el-upload>
        </el-col>
      </el-row>
      <el-table
        :data="InsuranceData"
        highlight-current-row
        border
        height="500"
        @current-change="selectCurrentRow1"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column prop="UserName" label="名称"> </el-table-column>
        <el-table-column label="身份证号" prop="IdCard"></el-table-column>
        <el-table-column label="金额" prop="Salary"></el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination
        align="center"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-size="pageSize1"
        layout="prev, pager, next, jumper,total"
        :page-count="total1"
        :total="totaldata1"
      >
      </el-pagination>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
//  import moment from 'moment/moment.js'
import * as validate from "../../../util/validate";
import {
  GetSalaryTaxMonthList,
  AddSalaryTaxMonth,
  DeleteSalaryTaxMonth,
  UpdateSalaryTaxMonth,
  GetSalaryList,
  AddSalaryTax,
  DeleteSalaryTax,
  UpdateSalaryTax,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar },
  data() {
      return {
          token: { "Authorization": "Bearer " + window.localStorage.getItem("access_token") },
      fileListUpload: [],
      searchVal: "",
      disbtn: false,
      loading: true,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      currentPage1: 1, // 当前页码
      total1: null, // 总条数
      totaldata1: null,
      pageSize1: 20, // 每页的数据条数
      page1: 1,
      isDisabled: false,
      SakaryVisible: false,
      InsuranceVisible: false,
      SakaryAllFormVisible: false,
      buttonListmsg: "",
      users: [],
      InsuranceData: [],
      operation: false, // true:新增, false:编辑
      buttonList: [],
      multipleSelection: [],
      PId: "",
      selectForm: {
        userName: "",
        month: "",
        year: "",
      },
      selectForm1: {
        Year: "",
        Month: "",
      },
      SakaryForm: [],
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    selectCurrentRow1(val) {
      this.currentRow1 = val;
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.page1 = val;
      this.getInsuranceData(false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        Year: this.selectForm1.Year,
        Month: this.selectForm1.Month,
      };

      GetSalaryTaxMonthList(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getInsuranceData(isCurrent = true) {
      if (isCurrent) {
        this.page1 = 1;
      }
      let para = {
        page: this.page1,
        size: this.pageSize1,
        PId: this.PId,
        userName: this.selectForm.userName,
        year: this.selectForm.year,
        month: this.selectForm.month,
      };
      GetSalaryList(para).then((res) => {
        console.log(res);
        this.total1 = res.data.response.pageCount;
        this.totaldata1 = res.data.response.dataCount;
        this.InsuranceData = res.data.response.data;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "未锁定") {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }

      this.PId = row.Id;
      this.getInsuranceData();
      this.InsuranceVisible = true;
    },
    handleInEdit() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      this.SakaryForm = Object.assign({}, row);
      this.SakaryVisible = true;
    },

    handleAdd() {
      AddSalaryTaxMonth().then((res) => {
        if (res.data.success) {
          this.$message({
            message: "添加成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "添加失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      if (row.State == "已锁定") {
        this.$message({
          message: "锁定状态的数据不允许删除！",
          type: "error",
        });
        return;
      }
      let para = {
        id: row.Id,
      };

      DeleteSalaryTaxMonth(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });
          this.SakaryVisible = false;
          this.getData(false);
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleInDel() {
      let row = this.currentRow1;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });
        return;
      }

      let para = {
        id: row.Id,
      };

      DeleteSalaryTax(para).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "删除成功",
            type: "success",
            duration: 5000,
          });

          this.getInsuranceData();
        } else {
          this.$message({
            message: "删除失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
    handleLock() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要锁定的一行数据！",
          type: "error",
        });
        return;
      }
      this.$confirm("确定要锁定吗？", "提示", {}).then(() => {
        UpdateSalaryTaxMonth({ id: row.Id }).then((res) => {
          console.log(res);
          if (res.data.success) {
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 5000,
            });
            this.getData(false);
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              duration: 5000,
            });
          }
        });
      });
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    inputAllMoney(name) {
      if (!validate.validateIsMoney(this.SakaryAllForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          UpdateSalaryTax(para).then((res) => {
            if (res.data.success) {
              this.$message({
                message: "修改成功",
                type: "success",
                duration: 5000,
              });
              this.SakaryVisible = false;
              this.getInsuranceData();
            } else {
              this.$message({
                message: "修改失败",
                type: "error",
                duration: 5000,
              });
            }
          });
        });
      });
    },
    doDown() {
        //let hrefStr = encodeURI("/api/SalaryTax/DownloadTemplate");
        let hrefStr = encodeURI("https://attachment.gradgroup.cn/uc/default/2021-11/3a001471-b19a-e874-eaba-6a3ba284fa7b.xlsx");
      window.location.href = hrefStr;
    },

    // 上传附件
    handleChange(file, fileList) {
      console.log(this.PId);
      this.fileTemp = file.raw;
      (this.disbtn = true), this.importfxx(this.fileTemp, this.PId);
    },
    // Excel 转化
    importfxx(obj, pid) {
      let _this = this;
      // 通过DOM取文件数据
      this.file = obj;
      var rABS = false; // 是否将文件读取为二进制字符串
      var f = this.file;
      var reader = new FileReader();
      // if (!FileReader.prototype.readAsBinaryString) {
      FileReader.prototype.readAsBinaryString = function (f) {
        var binary = "";
        var rABS = false; // 是否将文件读取为二进制字符串
        var pt = this;
        var wb; // 读取完成的数据
        var outdata;
        var reader = new FileReader();
        reader.onload = function (e) {
          var bytes = new Uint8Array(reader.result);
          var length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          var XLSX = require("xlsx");
          if (rABS) {
            wb = XLSX.read(btoa(fixdata(binary)), {
              // 手动转化
              type: "base64",
            });
          } else {
            wb = XLSX.read(binary, {
              type: "binary",
            });
          }
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]); // outdata就是你想要的东西
          this.da = [...outdata];
          let arr = [];
          this.da.map((v) => {
            console.log(v);
            let obj = {};
            obj.UserName = v["姓名"];
            obj.IdCard = v["身份证号"];
            obj.Salary = v["个税金额"];
            obj.PId = pid;
            arr.push(obj);
            _this.list = [...arr];
          });
          _this.ExcelData(arr);
        };

        reader.readAsArrayBuffer(f);
      };
      if (rABS) {
        reader.readAsArrayBuffer(f);
      } else {
        reader.readAsBinaryString(f);
      }
    },
    ExcelData(arr) {
      AddSalaryTax(arr).then((res) => {
        if (res.data.success) {
          this.$message({
            message: "导入成功",
            type: "success",
            duration: 5000,
          });
          this.disbtn = false;
          this.getInsuranceData();
        } else {
          this.$message({
            message: res.data.masg,
            type: "error",
            duration: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
</style>

